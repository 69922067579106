<template>
  <div class="vx-row">
    <div class="vx-col sm:w-full md:w-1/2 mb-base">
      <vx-card title="Add Files">
        <!-- FORM START -->
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <input type="file" class='w-full' name='file' ref='file'/>
          </div>
        </div>
        
        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-input class="w-full" name="name" label="Name" v-model="name"></vs-input>
          </div>
        </div>

        <div class="vx-row mb-3">
          <div class="vx-col w-full">
            <vs-button color='primary' @click='store' size='medium' class='mr-2'>Submit</vs-button>
            <vs-button color='warning' size='medium' @click='$router.back()'>Back</vs-button>
          </div>
        </div>
        <!-- FORM END -->
      </vx-card>
    </div>
    
  </div>
</template>

<script>
import {mapActions} from 'vuex'
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import BrowseCarFactory from '@browse/CarFactory.vue'
import BrowseCarType from '@browse/CarType.vue'
import BrowseCarColor from '@browse/CarColor.vue'
import BrowseCompany from '@browse/Company.vue'
import BrowseContact from '@browse/Contact.vue'
import BrowseRoute from '@browse/Route.vue'
import BrowseContactAddress from '@browse/ContactAddress.vue'
import BrowseVehicleType from '@browse/VehicleType.vue'
import BrowseCommodity from '@browse/Commodity.vue'
import BrowseUnit from '@browse/Unit.vue'
import BrowsePriceList from '@browse/PriceList.vue'

export default {
  props:{
    id: {
      type: String,
      default: null
    }
  },
  data() {
    return {
        name : null,
        fd : {
            name
        }  
    }
  },
  methods:{
    ...mapActions({
      dispatchStore: 'job_order_files/store',
      dispatchUpdate: 'job_order_files/update',
      dispatchShow: 'job_order_files/show'
    }),
    
    async store(){
        console.log(this.$refs.file)
        let payload = new FormData()
        if(this.name) {
            payload.append('name', this.name)
        }
        if(this.$refs.file.files.length > 0) {
            payload.append('file', this.$refs.file.files[0])
        }
        this.$vs.loading()
        payload = {
            'payload' : payload, 
            job_order_id : this.$route.params.job_order_id,
            id : this.$route.params.id
        }
        try {

          if (this.$route.params.id) {
            let {data} = await this.dispatchUpdate(payload)
          } else {
            let {data} = await this.dispatchStore(payload)
          }
          this.$vs.notify({
            title: `OK`,
            text: `Data saved successfully`,
            color: `success`
          })
            this.$vs.loading.close()
            this.$router.back()
        } catch (error) {
            this.$vs.loading.close()
            if(error.status == 422) {
                  for(let x in error.data) {
                      this.$vs.notify({
                        title: 'Oops!',
                        text: error.data[x][0],
                        color: 'danger'
                      })
                      break
                  }
            } else {
                  this.$vs.notify({
                    title: 'Oops!',
                    text: error.data.message,
                    color: 'danger'
                  })
            }
        }
    },

    async getDetail(){
      let payload = {
          job_order_id : this.$route.params.job_order_id,
          id : this.$route.params.id
      }
      let { data } = await this.dispatchShow(payload)
      this.name = data.name
    },
    
  },
  mounted(){
    // console.log(this.ids)
    if (this.$route.params.id) {
      this.getDetail()
    }
  }
}
</script>

<style>

</style>